import getBrowserErrorType, {
  BrowserErrorType,
} from "@reservauto/react-shared/getBrowserErrorType";
import importRetry from "@reservauto/react-shared/importRetry";
import LocalStorageUtil from "@reservauto/react-shared/localStorage/LocalStorageUtil";
import Logging from "@reservauto/react-shared/Logging";
import { localeStorageKey } from "@reservauto/react-shared/stores/languageStore";
import userStore from "@reservauto/react-shared/stores/userStore";
import { User } from "oidc-client-ts";
import appUrlParams from "./appUrlParams";
import { BranchAvailableLanguagesService } from "./areas/branch/service";
import { LogService } from "./areas/general/service";
import authenticationService from "./frame/authenticationService";
import authenticationWebViewService from "./frame/authenticationWebViewService";
import { UserMessage } from "./frame/CriticalErrorPage";
import appSettings from "./shared/appSettings";
import envSettings from "./shared/envSettings";
import identityProviderData from "./shared/identityProviderData";
import branchStore from "./shared/stores/branchStore";
import userFOStore, { UserProfile } from "./shared/stores/userFOStore";

LocalStorageUtil.keyPrefix = "CustomerSpace";
Logging.initialize(
  (l) => new LogService().postLog(l),
  window.getCustomerSpaceSiteUrl(),
  envSettings.VITE_BUILDNUM,
  appSettings.Env,
  envSettings.VITE_LOGLEVELS
    ? envSettings.VITE_LOGLEVELS.toLowerCase().split(",")
    : [],
);

const anonymousRoutes = [
  "/contactus",
  "/emailconfirmation",
  "/passwordrecovery",
];
const currentRoute = window.location.pathname.toLowerCase();

function showCriticalErrorPage(
  exception?: unknown,
  userMessage?: UserMessage,
  user?: User | null,
): void {
  let message = userMessage;
  if (!message && exception) {
    switch (getBrowserErrorType(exception)) {
      case BrowserErrorType.cache:
        message = "BrowserCache";
        break;
      case BrowserErrorType.plugin:
        message = "BrowserPlugin";
        break;
    }
  }

  importRetry(() => import("./frame/CriticalErrorPage"))
    .then((errorPage) => errorPage.mount(exception, message, user !== null))
    .catch((newException) => {
      let logException = exception as { [name: string]: unknown };
      if (logException && typeof logException === "object") {
        logException.criticalErrorPageError = newException;
      } else {
        logException = {
          criticalErrorPageError: newException,
          error: logException,
        };
      }

      Logging.error(logException).finally(() =>
        window.navigateToFatalErrorPage(),
      );
    });
}

function showSignoutPage(state?: string): void {
  importRetry(() => import("./frame/Signout"))
    .then((signout) => {
      if (typeof signout.mount !== "function") {
        showCriticalErrorPage(
          new Error("signout.mount is not a function"),
          "BrowserPlugin",
        );
      } else {
        signout.mount(state);
      }
    })
    .catch((ex) => showCriticalErrorPage(ex));
}

async function signinRedirect(user: User | null, url?: string): Promise<void> {
  if (user) {
    userFOStore.populate(user);
  }

  const userProfile = user?.profile as UserProfile | undefined;
  let branchId = appUrlParams.branchId ?? userProfile?.branchId ?? 1;
  branchId = typeof branchId === "string" ? parseInt(branchId) : branchId;

  branchStore.setById(branchId);
  await branchStore.populateLanguages((branch) =>
    new BranchAvailableLanguagesService().getAvailableLanguage({
      branchId: branch,
    }),
  );

  let locale = appUrlParams.locale;
  if (!locale) {
    locale = LocalStorageUtil.get(localeStorageKey, "");
  }
  if (!locale) {
    locale = window.navigator.language;
  }

  locale = branchStore.correctLocale(locale);

  authenticationService
    .signinRedirect(branchId, locale, url)
    .catch((ex) => showCriticalErrorPage(ex, undefined, user));
}

if (identityProviderData) {
  importRetry(() => import("./areas/customer/authentication/Authentication"))
    .then((auth) => auth.mount())
    .catch((ex) => showCriticalErrorPage(ex));
} else {
  try {
    switch (currentRoute) {
      case "/account/login":
        importRetry(() => import("./frame/Signin"))
          .then((signin) => signin.mount())
          .catch((ex) => showCriticalErrorPage(ex));
        break;

      case "/legacy/login":
        signinRedirect(null, "/").catch((ex) => showCriticalErrorPage(ex));
        break;

      case "/signin-callback":
        authenticationService
          .signinCallback()
          .then((newUser) => {
            userFOStore.populate(newUser);
            authenticationService.completeSignin();
            const isLocalUrl =
              newUser.state &&
              typeof newUser.state === "string" &&
              !newUser.state.includes("//");

            window.location.href = isLocalUrl ? newUser.state : "/";
          })
          .catch((ex) => {
            const timeErrors = [
              "exp is in the past",
              "iat is in the future",
              "nbf is in the future",
            ];
            if (timeErrors.some((e) => ex?.message?.startsWith(e))) {
              showCriticalErrorPage(ex, "DeviceTime");
            } else if (
              [
                "No matching state found in storage",
                "No state in response",
                "invalid_grant",
              ].includes(ex?.message)
            ) {
              authenticationService
                .getUser()
                .then((user) =>
                  signinRedirect(user, "/").catch((e) =>
                    showCriticalErrorPage(e, undefined, user),
                  ),
                )
                .catch((e) => showCriticalErrorPage(e));
            } else {
              showCriticalErrorPage(ex);
            }
          });
        break;

      case "/signout-callback":
        authenticationService
          .signoutCallback()
          .then((signoutReponse) =>
            showSignoutPage(signoutReponse.userState as string),
          )
          .catch((ex) => {
            if (ex?.message === "No matching state found in storage") {
              Logging.warning(ex);
            } else {
              Logging.error(ex);
            }
            showSignoutPage();
          });
        break;

      case "/silent-callback":
        authenticationService
          .silentCallback()
          .catch((ex) => showCriticalErrorPage(ex));
        break;

      default:
        if (appUrlParams.isMobileAppWebView) {
          let initialized = false;
          authenticationWebViewService.listenForMessages((token) => {
            userStore.setAccessToken(token);

            if (!initialized) {
              initialized = true;
              importRetry(() => import("./App"))
                .then((app) => app.mount(null))
                .catch((ex) => showCriticalErrorPage(ex));
            }
          });
          authenticationWebViewService.notifyReady();
        } else {
          authenticationService
            .getUser()
            .then((user) => {
              if (
                !user &&
                !appUrlParams.branchId &&
                appSettings.Env !== "Local"
              ) {
                window.location.href = "https://communauto.com";
              } else if (
                (!anonymousRoutes.includes(currentRoute) ||
                  appUrlParams.login) &&
                (!user || authenticationService.shouldRenewSession(user))
              ) {
                signinRedirect(user).catch((ex) =>
                  showCriticalErrorPage(ex, undefined, user),
                );
              } else {
                let authenticatedUser: User | null = null;
                if (user && !authenticationService.shouldRenewSession(user)) {
                  authenticatedUser = user;
                  userFOStore.populate(authenticatedUser);
                }

                importRetry(() => import("./App"))
                  .then((app) => {
                    if (typeof app.mount !== "function") {
                      showCriticalErrorPage(
                        new Error("app.mount is not a function"),
                        "BrowserPlugin",
                      );
                    } else {
                      app.mount(authenticatedUser);
                    }
                  })
                  .catch((ex) =>
                    showCriticalErrorPage(ex, undefined, authenticatedUser),
                  );
              }
            })
            .catch((ex) => showCriticalErrorPage(ex));
        }
    }
  } catch (ex) {
    showCriticalErrorPage(ex);
  }
}
